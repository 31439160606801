import React from 'react';
import { useLocation } from 'react-router';
import { useState } from 'react';
import styled from 'styled-components';
import Announcement from '../components/Announcement';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import Newsletter from '../components/Newsletter';
import Products from '../components/Products';
import Categories from '../components/Categories';
import CategoryFilter from '../components/CategoryFilter';
import Category from '../components/Category';


const Container = styled.div`

`;

const Title = styled.h1`
margin: 30px;
text-align : center;
color : #000084;

`;
const FilterContainer = styled.div`
display: flex;
justify-content: space-between;
`;
const Filter = styled.div`
margin: 20px;
`;
const FilterText = styled.span`
font-size: 20px;
font-weight: 600;
margin: 20px;
`;
const Select = styled.select`
 padding: 10px;
 margin-right: 20px;
 `;
const Option = styled.option`
 
 `;


const ProductList = () => {

  const location = useLocation();
  const category = location.pathname.split("/")[2];
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState("newest");

  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
    console.log(value);
  };
  return (
    <Container>
      <NavBar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ borderRight: "5px solid #eee" }}>
            <Category />
          </div>
          <div className="col md-9">
            <Title> Produit {category.replace("%C3%A9","é").replace("%C3%A9","é")}</Title>

            <Products category={category} filters={filters} sort={sort} />
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
      {/* <FilterContainer>
        <Filter>
          <FilterText>Filter Products:</FilterText>
          <Select name="color" onChange={handleFilters}>
            <Option disabled>
              Color
            </Option>
            <Option>white</Option>
            <Option>black</Option>
            <Option>red</Option>
            <Option>blue</Option>
            <Option>yellow</Option>
            <Option>green</Option>
          </Select>
          <Select name="size" onChange={handleFilters}>
            <Option disabled>
              Size
            </Option>
            <Option>XS</Option>
            <Option>S</Option>
            <Option>M</Option>
            <Option>L</Option>
            <Option>XL</Option>
          </Select>
        </Filter>
        <Filter>
          <FilterText>Sort Products :</FilterText>
          <Select onChange={(e) => setSort(e.target.value)}>
            <Option disabled>
              Color
            </Option>
            <Option >Newest</Option>
            <Option value="asc">Price (asc)</Option>
            <Option value="desc">Price (desc)</Option>
          </Select>
        </Filter>
      </FilterContainer> */}
      {/* <Products category={category} filters={filters} sort={sort} /> */}

    </Container>


  )
}

export default ProductList
