import React from 'react';
import styled from 'styled-components';
import { BiSupport } from 'react-icons/bi';
import { FaAward, FaShippingFast } from 'react-icons/fa'
import { SiHellofresh } from 'react-icons/si'
const Announcement = () => {
    return (
        <section class="ftco-section" style={{ backgroundColor: "#eee", margin: '20px 0', padding: '30px' }}>
            <div class="container">
                <div class="row no-gutters ftco-services">
                    <div class="col-md-3 text-center d-flex align-self-stretch ftco-animate fadeInUp ftco-animated" style={{ justifyContent: 'center' }}>
                        <div class="media block-6 services mb-md-0 mb-4">
                            <div class="icon bg-color-1 active d-flex justify-content-center align-items-center mb-2">
                                <FaShippingFast size={100} color={"#000084"} />
                            </div>
                            <div class="media-body">
                                <h3 class="heading">Free Shipping</h3>
                                <span>On order over 100 Dt</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ justifyContent: 'center' }} class="col-md-3 text-center d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div class="media block-6 services mb-md-0 mb-4">
                            <div class="icon bg-color-2 d-flex justify-content-center align-items-center mb-2">
                                <SiHellofresh size={100} color={"#000084"} />
                            </div>
                            <div class="media-body">
                                <h3 class="heading">Always Fresh</h3>
                                <span>Product well package</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ justifyContent: 'center' }} class="col-md-3 text-center d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div class="media block-6 services mb-md-0 mb-4">
                            <div class="icon bg-color-3 d-flex justify-content-center align-items-center mb-2">
                                <FaAward size={100} color={"#000084"} />
                            </div>
                            <div class="media-body">
                                <h3 class="heading">Superior Quality</h3>
                                <span>Quality Products</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ justifyContent: 'center' }} class="col-md-3 text-center d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div class="media block-6 services mb-md-0 mb-4">
                            <div class="icon bg-color-4 d-flex justify-content-center align-items-center mb-2">
                                <BiSupport size={100} color={"#000084"} />
                            </div>
                            <div class="media-body">
                                <h3 class="heading">Support</h3>
                                <span>24/7 Support</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Announcement
