import React, { useEffect, useState } from 'react';
import Announcement from '../components/Announcement';
import Categories from '../components/Categories';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Newsletter from '../components/Newsletter';
import Products from '../components/Products';
import Slider from '../components/Slider';
import { useSelector } from 'react-redux';
import PayComponent from '../components/PayComponent';
const Home = () => {

    const [creditpay, setCreditPay] = useState(false)
    const user = useSelector((state) => state.user.currentUser);

    console.log('user', user)
    const d = new Date();
    let day = d.getDate();
    console.log(day)

    useEffect(() => {
        if (user) {
            const credit = user.credit
            console.log(credit)
            if (day == 1) {
                if (credit != 0) {
                    setCreditPay(true)
                }
            }
        }
    })
    return (
        <>
            {
                (creditpay) ? <PayComponent credit={user.credit} /> : <div>

                    <Navbar />
                    <Slider />
                    <Announcement />
                    <Categories />
                    {/* <Products /> */}
                    <Newsletter />
                    <Footer />
                </div>
            }

        </>

    )
}
export default Home
