import React from 'react';
import styled from 'styled-components';
import { Search, ShoppingCartOutlined } from '@material-ui/icons';
import { Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import './styles.css'
const Container = styled.div`
height : 100px ;
background-color:#eee;
`
const Wrapper = styled.div`
padding : 0px 20px ;
display:flex;
align-items: center;
justify-content:space-between;
`
const Elements = styled.div`
padding : 0 6px;
display : flex
`
const Left = styled.div`
flex:1;
display: flex;
align-items: center;
`;
const Language = styled.div`
font-size:14px;
cursor:pointer;
`;
const SearchContainer = styled.div`
border: 0.5px solid lightgray;
display: flex;
align-items: center;
margin-left: 25px;
padding: 5px;
`;
const Input = styled.input`
border: none;
`
const Center = styled.div`
flex:1;
text-align: center;
`;
const Logo = styled.h1`
font-weight: bold;
color : #fff;
cursor : pointer;
`;
const Right = styled.div`
flex:1;
display: flex;
align-items: center;
justify-content: flex-end;
`;
const MenuItem = styled.div`
font-size: 18px;
cursor: pointer;
margin-left: 25px;
color: #053C48;
font-weight : 600;

`;
const BtnLogout = styled.button`
background-color: transparent;
color: #053C48;
font-size: 18px;
font-weight : 600;
border: none;
cursor: pointer;
`

const NavBar = () => {
    const user = useSelector((state) => state.user.currentUser);
    // const cartQuantity = useSelector(state => state.cart.cartQuantity);
    const { cartTotalQuantity } = useSelector((state) => state.cartShopping);
    return (
        <Container>
            <Wrapper>
                <Left>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <Logo><img src="https://i.imgur.com/VzxigFa.png" style={{ maxWidth: "200px" }} /></Logo>
                    </Link>
                </Left>
                <Right>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <MenuItem>
                            Home
                        </MenuItem>
                    </Link>
                    <Link to="/products" style={{ textDecoration: "none" }}>
                        <MenuItem>
                            Products
                        </MenuItem>
                    </Link>

                    {
                        user ? <MenuItem>
                            Solde : <span style={{ color: "red" }}> {user.solde} TND</span>

                        </MenuItem>
                            : ''
                    }


                    {user ? '' :
                        <Elements>
                            <Link to="/register" style={{ textDecoration: "none" }}>
                                <MenuItem>
                                    SIgnUp
                                </MenuItem>
                            </Link>
                            <Link to="/login" style={{ textDecoration: "none" }}>
                                <MenuItem>
                                    SIgnIn
                                </MenuItem>
                            </Link>
                        </Elements>

                    }
                    <Link to="/cart">
                        <MenuItem>
                            <Badge badgeContent={cartTotalQuantity} style={{ color: "#fff", cursor: 'pointer' }}>
                                <ShoppingCartOutlined style={{ color: '#053c48' }} />
                            </Badge>
                        </MenuItem>
                    </Link>
                    {
                        user ?

                            <MenuItem>
                                <BtnLogout onClick={() => localStorage.clear()}>
                                    Logout
                                </BtnLogout>
                            </MenuItem>

                            : ''
                    }
                </Right>
            </Wrapper>
        </Container>
    )
}

export default NavBar
