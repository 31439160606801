import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import styled from 'styled-components';
import { Add, Remove } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { publicRequest } from '../requestHttp';
import { Link } from 'react-router-dom';
import axios from 'axios'
function Orders() {
    const userId = useSelector(state => state?.user.currentUser._id);
    const [order, setOrder] = useState([])
    useEffect(() => {

        try {
            const res = axios.get(`http://41.231.122.34:5000/api/order/find/${userId}`
            );
            // navigate("/success", { data: res.data });
            console.log(res)
            setOrder(res)
        } catch (err) {
            console.log(err);

        }

    }, [])
    console.log('order', order)
    return (
        <div>orders</div>
    )
}

export default Orders