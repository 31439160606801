import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;
const Icon = styled.img`
display: flex;
margin-top: 8vh;
width: 300px;
`;
const Button = styled.button`
display:flex ;
align-items:center ;
justify-content:center ;
width: 200px;
height:50px ;
border:none ;
margin-top:30px ;
background-color: teal;
color: white;
font-size: 15px;
cursor: pointer;
text-decoration:none ;

`;
const Text = styled.h1`
display: flex;
justify-content: center;
align-items: center;
font-size: 23px;
margin-top: 10vh;

`;
const Text2 = styled.h3`
margin-top:100px ;
justify-content: center;
align-items: center;
font-size: 23px;
margin-top: 50px;

`;

const Map = () => {

    return (
        <Container>

           <Text>Trouver ci-dessous le magasin le plus proche pour recevoir votre commande</Text>
           <Icon src="https://www.ydesignservices.com/wp-content/uploads/2016/07/Googlemap.jpg"/>   
           <Link to="/">
                 <Button>retour à l'accueil</Button>
               </Link>  
        </Container>
    )
}

export default Map
