import React from 'react';
import CategoryItem from './CategoryItem';
import { categories } from '../data';
import styled from 'styled-components';
import CategoryFilter from './CategoryFilter';



const Container = styled.div`
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


`;
const Title = styled.h2`
color : #000084 ;
font-size : 25px;
text-align : center;
font-weight : 600
`
const Category = () => {
    return (
        <div className='container-fluid' style={{ padding: "30px" }}>
            <div className="row">
                <Title>Our Categories</Title>
                {categories.map(item => (
                    <CategoryFilter item={item} key={item.id} />
                ))}
            </div>
        </div>
    )
}

export default Category
