export const products = [
  {
    id: 1,
    img: "https://i.imgur.com/zZLvLv0.png",
    title: "yaourt Liégeois",
    desc: "This is a Sale , i hope you enjoy it .",
    price: "0.5",
  },
  {
    id: 2,
    img: "https://i.imgur.com/ncaGgBJ.png",
    title: "Thon El Manar",
    desc: "This is a Sale , i hope you enjoy it .",
    price: "4.3",
  },
  {
    id: 3,
    img: "https://i.imgur.com/GnJosVW.png",
    title: "Nescafé",
    desc: "This is a Sale , i hope you enjoy it .",
    price: "2.7",
  },
  {
    id: 3,
    img: "https://i.imgur.com/jFUKr75.png",
    title: "Chocoline",
    desc: "This is a Sale , i hope you enjoy it .",
    price: "3.7",
  },
];

export const categories = [
  {
    id: 1,
    img: "https://i.imgur.com/nLzrsNO.jpg",
    title: "Produit Laitiers",
    cat: "Laitiers",
  },
  {
    id: 2,
    img: "https://i.imgur.com/0OQW255.jpg",
    title: "Epicerie Sucrée",
    cat: "Sucrée",
  },
  {
    id: 3,
    img: "https://i.imgur.com/YDYQEVe.jpg",
    title: "Epicerie Salée",
    cat: "Salée",
  },
  {
    id: 4,
    img: "https://i.imgur.com/fZMyOWw.jpg",
    title: "Boissons",
    cat: "Boissons",
  },
  {
    id: 5,
    img: "https://i.imgur.com/podUh9a.jpg",
    title: "Maison",
    cat: "Maison",
  },
  {
    id: 6,
    img: "https://i.imgur.com/zZhPkw7.jpg",
    title: "Hygiène",
    cat: "Hygiène",
  },
  {
    id: 7,
    img: "https://i.imgur.com/7SVGm3m.jpg",
    title: "Bébé",
    cat: "Bébé",
  },
  {
    id: 8,
    img: "https://i.imgur.com/iueNKzA.jpg",
    title: "Surgelés",
    cat: "Surgelés",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100013081.jpg",
  },
  {
    id: 2,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100028331_e89a.jpg",
  },
  {
    id: 3,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100158851_39e4.jpg",
  },
  {
    id: 4,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100018171_0d04.jpg",
  },
  {
    id: 5,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/5/e/5ef3f1940aa66a585f2a4f411bef632e53802f19_100025291_cabd.png",
  },
  {
    id: 6,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100505061_0bfd.jpg",
  },
  {
    id: 7,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100314771_8243.jpg",
  },
  {
    id: 8,
    img: "https://aziza.tn/media/catalog/product/cache/b5981f94a096ce8ed249e81adce0f478/1/0/100018741_9542.jpg",
  },
];
