import Home from './pages/Home';
import Product from './pages/Product';
import Cart from './pages/Cart';
import Login from './pages/Login';
import Register from './pages/Register';
import ProductList from './pages/ProductList';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Redirect,
  Route
} from "react-router-dom";
import Success from './pages/Success';
import Map from './pages/Map';
import Orders from './components/orders';
import AllProducts from './pages/AllProducts';
function App() {

  const user = useSelector((state) => state.user.currentUser);
  console.log(user)
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:category" element={<ProductList />} />
        <Route path="/products/" element={<AllProducts />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<Success />} />
        <Route path="/map" element={<Map />} />
        <Route path="/order" element={<Orders />} />
      </Routes>
    </Router>
  );
}

export default App;
