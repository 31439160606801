import { SearchOutlined, ShoppingCartOutlined } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { addToCart } from "../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
const Info = styled.div`
opacity: 0;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.2);
z-index: 3;
display: flex;
align-items: center;
justify-content: center;
transition: all 0.5s ease;
cursor: pointer;

`;
const Container = styled.div`
flex: 1;
margin: 5px;
min-width: 280px;
height: 350px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
background-color: #f5fbfd;

&:hover ${Info} {
    opacity: 1;
    
}

`;
const Circle = styled.div`

`;
const Image = styled.img`
height: 75%;
z-index: 2;

`;

const Icon = styled.div`
width: 40px;
height: 40px;
border-radius: 50px;
margin:10px;
background-color: white;
align-items: center;
justify-content: center;
display: flex;
transition: all 0.5s ease;

&:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
}


`;


const Product = ({ item }) => {
    const dispatch = useDispatch();
    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
        console.log('item', item)
    };
    return (
        // <Container>
        //     <Circle/>
        //     <Image src={item.img} />
        //     <Info>
        //         <Icon>
        //             <ShoppingCartOutlined/>
        //         </Icon>
        //         <Icon>
        //             <Link to={`/product/${item._id}`}>
        //             <SearchOutlined/>
        //             </Link>
        //         </Icon>
        //         <Icon>
        //             <FavoriteBorderOutlined/>
        //         </Icon>
        //     </Info>
        // </Container>
        <div className="col-md-4">
            <div className="card">
                <div className="imgBox">
                    <img src={item.img} alt="" className="mouse" />
                </div>
                <div className="contentBox">
                    <h3>{item.title}</h3>
                    <h2 className="price">{item.price} dt</h2>
                    <div className="buy">
                        <div className="jtv-extra-link">

                            <a className="button-cart" onClick={() => handleAddToCart(item)}><ShoppingCartOutlined style={{ color: '#fff' }} /></a>

                            <a href={`/product/${item._id}`} className="button-cart"><SearchOutlined style={{ color: '#fff' }} /> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Product
