import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;
const Icon = styled.img`
display: flex;
margin-top: 15vh;
width: 200px;
`;
const Button = styled.button`
display:flex ;
align-items:center ;
justify-content:center ;
width: 200px;
height:50px ;
border:none ;
margin-top:10px ;
background-color: teal;
color: white;
font-size: 15px;
cursor: pointer;
`;
const Text = styled.h1`
display: flex;
justify-content: center;
align-items: center;
font-size: 23px;
margin-top: 10px;

`;
const Text2 = styled.h3`
margin-top:100px ;
justify-content: center;
align-items: center;
font-size: 23px;
margin-top: 50px;

`;

const Success = () => {
    
    const location = useLocation();
    console.log(location)
    return (
        <Container>
           <Icon src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx6wwXILjlAknAbShbBHsd0CBgdt5_gER0Yg&usqp=CAU"/>
           <Text>Votre commande a été crée avec succées.</Text>

               <Text2>  rechercher la magasin le plus proche.</Text2>
               <Link to="/map">
                 <Button>ici</Button>
               </Link>
               
        </Container>
    )
}

export default Success
