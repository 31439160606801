import { ArrowLeftOutlined, ArrowRightOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { sliderItems } from '../data';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
`;


const Wrapper = styled.div`
width: 100%;
display: flex;
align-items : center;
justify-content : space-around;
`;

const ImgContainer = styled.div`
height: 100%;
flex: 1;
display : flex;

`;
const Image = styled.img`
width:100%;
display: block;
margin-left: auto;
margin-right: auto;
`;
const InfoContainer = styled.div`
flex: 1;
text-align : center;
`;
const Title = styled.h1`
font-size: 70px;
color : #4caf50;
`;
const Description = styled.p`
font-size: 35px;
font-weight: 500;
letter-spacing: 3px;
color : #053C48;
`;
const Logo = styled.img`
width : 100%
`

const Slider = () => {

  return (
    <Container>
      {/* <Arrow direction="left" onClick={()=> handleClick("left")}>
              <ArrowLeftOutlined/>
          </Arrow> */}
      <Wrapper>

        <ImgContainer>
          <Image src="https://i.imgur.com/VyVBizB.png" />
        </ImgContainer>
        <InfoContainer>
          <Title> <Logo src='https://i.imgur.com/VzxigFa.png' /> </Title>
          <Description>Your daily need products... </Description>
        </InfoContainer>

      </Wrapper>

    </Container>
  )
}

export default Slider
