import { Add, Remove } from '@material-ui/icons';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Announcement from '../components/Announcement';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import Newsletter from '../components/Newsletter';
import { publicRequest } from '../requestHttp';
import { useDispatch } from 'react-redux';
import { addProduct } from '../redux/cartRedux';
import { addToCart } from "../redux/cartSlice";

const Container = styled.div`
`;
const Wrapper = styled.div`
padding: 50px;
display: flex;
`;
const ImgContainer = styled.div`
height : 100%;
display : flex;
`;
const Image = styled.img`
width: 60%;
display : block;
margin : auto;
border : 2px solid #053c48;
`;
const InfoContainer = styled.div`
flex: 1;
padding: 0px 5px;
margin: 50px;

`;
const Title = styled.h1`
font-weight: 500;
font-size : 2rem;
`;

const Desc = styled.p`
margin: 20px 0px;
font-size : 2rem;
font-weight: 500

`;
const Price = styled.span`
font-weight: 500;
font-size: 2rem;
`;
const FilterContainer = styled.div`
display: flex;
width: 50%;
margin: 30px 0px;
justify-content: space-between;
`;
const Filter = styled.div`
display: flex;
align-items: center;
`;
const FilterTitle = styled.span`
font-size: 2 rem;
font-weight: 200;
`;
const FilterColor = styled.div` 
width: 20px;
height: 20px;
border-radius: 50%;
background-color: ${(props) => props.color};
margin: 0px 5px;
cursor: pointer;
`;
const FilterSize = styled.select`
margin-left: 10px;
padding: 5px;
`;
const FilterSizeOption = styled.option`

`;
const AddContainer = styled.div`
width: 50%;
display: flex;
align-items: center;
justify-content: space-between;
`;
const AmountContainer = styled.div`
display: flex;
align-items: center;
font-weight: 700;
`;
const Amount = styled.span`
width: 30px;
height: 30px;
border-radius: 10px;
border: 1px solid teal;
display: flex;
align-items: center;
justify-content: center;
margin: 0px 5px;
`;
const Button = styled.button`
padding: 15px;
color : #fff;
background-color: #053c48;
border : none;
margin : auto;
margin-top : 30px;
cursor: pointer;
font-size : 18px;
width : 250px;
&:hover {
    background-color: #fff;
    border : 2px solid #053c48;
    color : #053c48;
}

`;

const Div = styled.div`
display : flex;
align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`

const Span = styled.span`
font-size: 2rem;
color : #053c48;
font-weight : 600;
margin-right : 15px
`

const Product = () => {

    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [color, setColor] = useState("");

    const [size, setSize] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        const getProduct = async () => {
            try {
                const res = await publicRequest.get("/product/find/" + id);
                setProduct(res.data);

            } catch (err) {
            }
        };
        getProduct();
    }, [id]);

    const handleQuantity = (type) => {
        if (type === "dec") {
            quantity > 1 && setQuantity(quantity - 1);
        } else {
            setQuantity(quantity + 1);
        }
    };

    const handleClick = () => {
        dispatch(
            addProduct(
                { ...product, quantity, color, size }
            ));
    };
    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
    };
    return (
        <Container>
            <NavBar />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <ImgContainer>

                            <Image src={product.img} />
                        </ImgContainer>

                    </div>
                    <div className="col-md-6" style={{ display: 'flex', alignItems: 'center' }}>
                        <InfoContainer>
                            <Div>
                                <Span>Product : </Span>:
                                <Title>{product.title}</Title>
                            </Div>
                            <Div>
                                <Span>Product descreption : </Span>
                                <Desc>{product.desc} jkhikhjlhlkjdvhdqkjqdmjv jfdmjk d fpdqkfkpdqo de difk</Desc>
                            </Div>
                            <Div>
                                <Span>Price : </Span>
                                <Price>{product.price} Dt</Price>
                            </Div>
                            <AddContainer>
                                {/* <AmountContainer>
                            <Remove onClick={() => handleQuantity("dec")} />
                            <Amount>{quantity}</Amount>
                            <Add onClick={() => handleQuantity("inc")} />
                        </AmountContainer> */}
                                <Button onClick={() => handleAddToCart(product)}>Add to cart</Button>
                            </AddContainer>
                        </InfoContainer>
                    </div>
                </div>
            </div>
            <Newsletter />
            <Footer />
        </Container>
    )
}

export default Product
