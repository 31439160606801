import axios from "axios";

const BASE_URL = "http://41.231.122.34:5000/api/";
const TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user || "{}")?.currentUser?.accessToken ;



export const publicRequest =  axios.create({
    baseURL : BASE_URL,
});

export const userLogin = axios.create({
    baseURL: BASE_URL ,
    headers: {token:`Bearer ${TOKEN}`},
})