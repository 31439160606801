import React from 'react'
import Announcement from '../components/Announcement';
import Categories from '../components/Categories';
import Category from '../components/Category';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Newsletter from '../components/Newsletter';
import Products from '../components/Products';
import Slider from '../components/Slider';
import styled from 'styled-components';
const Title = styled.h1`
margin: 30px;
text-align : center;
color : #000084;

`;
function AllProducts() {
    return (
        <div>  <Navbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <Category />
                    </div>
                    <div className="col-md-9">

                        <Title>Our Products</Title>
                        <Products />
                    </div>
                </div>
            </div>


            <Newsletter />
            <Footer /></div>
    )
}

export default AllProducts