import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
border-radius: 10px;
height: 180px;
max-width: 80%;
position: relative;
margin: auto;
margin-bottom: 30px;
margin-top: 30px;
`;
const Image = styled.img`
border-radius: 10px;
object-fit: cover;
margin: auto;
display: block;

`;
const Info = styled.div`
position: absolute;
bottom: 0;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;
const Title = styled.h1`
color: white;
margin-bottom: 20px;

`;
const Button = styled.button`
position:absolute ;
bottom:0 ;
left:0 ;
width:100% ;
border: none;
z-index:999 ;
padding: 10px;
background-color: #FFC100;
color: white;
cursor: pointer;
font-weight: 600;
border-radius:5px;


`;

const CategoryItem = ({ item }) => {
    return (
        <div className="col-md-3">
            <Container>
                <Link to={`/products/${item.cat}`} style={{ cursor: 'pointer' }}>
                    <Image src={item.img} />
                    {/* <Button>{item.title}</Button> */}
                </Link>
            </Container>
        </div>

    )
}

export default CategoryItem
